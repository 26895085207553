import laravel from "../laravelicon.png";
import livewire from "../livewire.png";
function Skills() {
  return (
    <div className="text-center mt-32">
      <div className="text-[65px] text-zinc-300 font-black ysabeau-sc">
        Skills
      </div>
      <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
        My current skillset
      </div>
      {/* Large device  */}
      <div className="2xl:mx-[10%] mx-[0%] lg:grid hidden">
        {/* First layer */}
        <div className="grid lg:grid-cols-8 mx-[10%] mt-16">
          <div className="justify-center flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 48 48"
              className=""
            >
              <path fill="#E65100" d="M41,5H7l3,34l14,4l14-4L41,5L41,5z"></path>
              <path fill="#FF6D00" d="M24 8L24 39.9 35.2 36.7 37.7 8z"></path>
              <path
                fill="#FFF"
                d="M24,25v-4h8.6l-0.7,11.5L24,35.1v-4.2l4.1-1.4l0.3-4.5H24z M32.9,17l0.3-4H24v4H32.9z"
              ></path>
              <path
                fill="#EEE"
                d="M24,30.9v4.2l-7.9-2.6L15.7,27h4l0.2,2.5L24,30.9z M19.1,17H24v-4h-9.1l0.7,12H24v-4h-4.6L19.1,17z"
              ></path>
            </svg>
          </div>
          <div className="justify-center flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 48 48"
            >
              <path fill="#0277BD" d="M41,5H7l3,34l14,4l14-4L41,5L41,5z"></path>
              <path fill="#039BE5" d="M24 8L24 39.9 35.2 36.7 37.7 8z"></path>
              <path
                fill="#FFF"
                d="M33.1 13L24 13 24 17 28.9 17 28.6 21 24 21 24 25 28.4 25 28.1 29.5 24 30.9 24 35.1 31.9 32.5 32.6 21 32.6 21z"
              ></path>
              <path
                fill="#EEE"
                d="M24,13v4h-8.9l-0.3-4H24z M19.4,21l0.2,4H24v-4H19.4z M19.8,27h-4l0.3,5.5l7.9,2.6v-4.2l-4.1-1.4L19.8,27z"
              ></path>
            </svg>
          </div>
          <div className="justify-center flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 48 48"
            >
              <path fill="#ffd600" d="M6,42V6h36v36H6z"></path>
              <path
                fill="#000001"
                d="M29.538 32.947c.692 1.124 1.444 2.201 3.037 2.201 1.338 0 2.04-.665 2.04-1.585 0-1.101-.726-1.492-2.198-2.133l-.807-.344c-2.329-.988-3.878-2.226-3.878-4.841 0-2.41 1.845-4.244 4.728-4.244 2.053 0 3.528.711 4.592 2.573l-2.514 1.607c-.553-.988-1.151-1.377-2.078-1.377-.946 0-1.545.597-1.545 1.377 0 .964.6 1.354 1.985 1.951l.807.344C36.452 29.645 38 30.839 38 33.523 38 36.415 35.716 38 32.65 38c-2.999 0-4.702-1.505-5.65-3.368L29.538 32.947zM17.952 33.029c.506.906 1.275 1.603 2.381 1.603 1.058 0 1.667-.418 1.667-2.043V22h3.333v11.101c0 3.367-1.953 4.899-4.805 4.899-2.577 0-4.437-1.746-5.195-3.368L17.952 33.029z"
              ></path>
            </svg>
          </div>
          <div className="justify-center flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 48 48"
            >
              <path
                fill="#80deea"
                d="M24,34C11.1,34,1,29.6,1,24c0-5.6,10.1-10,23-10c12.9,0,23,4.4,23,10C47,29.6,36.9,34,24,34z M24,16	c-12.6,0-21,4.1-21,8c0,3.9,8.4,8,21,8s21-4.1,21-8C45,20.1,36.6,16,24,16z"
              ></path>
              <path
                fill="#80deea"
                d="M15.1,44.6c-1,0-1.8-0.2-2.6-0.7C7.6,41.1,8.9,30.2,15.3,19l0,0c3-5.2,6.7-9.6,10.3-12.4c3.9-3,7.4-3.9,9.8-2.5	c2.5,1.4,3.4,4.9,2.8,9.8c-0.6,4.6-2.6,10-5.6,15.2c-3,5.2-6.7,9.6-10.3,12.4C19.7,43.5,17.2,44.6,15.1,44.6z M32.9,5.4	c-1.6,0-3.7,0.9-6,2.7c-3.4,2.7-6.9,6.9-9.8,11.9l0,0c-6.3,10.9-6.9,20.3-3.6,22.2c1.7,1,4.5,0.1,7.6-2.3c3.4-2.7,6.9-6.9,9.8-11.9	c2.9-5,4.8-10.1,5.4-14.4c0.5-4-0.1-6.8-1.8-7.8C34,5.6,33.5,5.4,32.9,5.4z"
              ></path>
              <path
                fill="#80deea"
                d="M33,44.6c-5,0-12.2-6.1-17.6-15.6C8.9,17.8,7.6,6.9,12.5,4.1l0,0C17.4,1.3,26.2,7.8,32.7,19	c3,5.2,5,10.6,5.6,15.2c0.7,4.9-0.3,8.3-2.8,9.8C34.7,44.4,33.9,44.6,33,44.6z M13.5,5.8c-3.3,1.9-2.7,11.3,3.6,22.2	c6.3,10.9,14.1,16.1,17.4,14.2c1.7-1,2.3-3.8,1.8-7.8c-0.6-4.3-2.5-9.4-5.4-14.4C24.6,9.1,16.8,3.9,13.5,5.8L13.5,5.8z"
              ></path>
              <circle cx="24" cy="24" r="4" fill="#80deea"></circle>
            </svg>
          </div>
          <div className="justify-center flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 48 48"
            >
              <polygon
                fill="#81c784"
                points="23.987,17 18.734,8 2.974,8 23.987,44 45,8 29.24,8"
              ></polygon>
              <polygon
                fill="#455a64"
                points="29.24,8 23.987,17 18.734,8 11.146,8 23.987,30 36.828,8"
              ></polygon>
            </svg>
          </div>
          <div className="justify-center flex">
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 48 48"
            >
              <path
                fill="#21a366"
                d="M24.007,45.419c-0.574,0-1.143-0.15-1.646-0.44l-5.24-3.103c-0.783-0.438-0.401-0.593-0.143-0.682	c1.044-0.365,1.255-0.448,2.369-1.081c0.117-0.067,0.27-0.043,0.39,0.028l4.026,2.389c0.145,0.079,0.352,0.079,0.486,0l15.697-9.061	c0.145-0.083,0.24-0.251,0.24-0.424V14.932c0-0.181-0.094-0.342-0.243-0.432L24.253,5.446c-0.145-0.086-0.338-0.086-0.483,0	L8.082,14.499c-0.152,0.086-0.249,0.255-0.249,0.428v18.114c0,0.173,0.094,0.338,0.244,0.42l4.299,2.483	c2.334,1.167,3.76-0.208,3.76-1.591V16.476c0-0.255,0.2-0.452,0.456-0.452h1.988c0.248,0,0.452,0.196,0.452,0.452v17.886	c0,3.112-1.697,4.9-4.648,4.9c-0.908,0-1.623,0-3.619-0.982l-4.118-2.373C5.629,35.317,5,34.216,5,33.042V14.928	c0-1.179,0.629-2.279,1.646-2.861L22.36,3.002c0.994-0.562,2.314-0.562,3.301,0l15.694,9.069C42.367,12.656,43,13.753,43,14.932	v18.114c0,1.175-0.633,2.271-1.646,2.861L25.66,44.971c-0.503,0.291-1.073,0.44-1.654,0.44"
              ></path>
              <path
                fill="#21a366"
                d="M28.856,32.937c-6.868,0-8.308-3.153-8.308-5.797c0-0.251,0.203-0.452,0.455-0.452h2.028	c0.224,0,0.413,0.163,0.448,0.384c0.306,2.066,1.218,3.108,5.371,3.108c3.308,0,4.715-0.747,4.715-2.502	c0-1.01-0.401-1.76-5.54-2.263c-4.299-0.424-6.955-1.371-6.955-4.809c0-3.167,2.672-5.053,7.147-5.053	c5.026,0,7.517,1.745,7.831,5.493c0.012,0.13-0.035,0.255-0.122,0.35c-0.086,0.09-0.208,0.145-0.334,0.145h-2.039	c-0.212,0-0.397-0.149-0.44-0.354c-0.491-2.173-1.678-2.868-4.904-2.868c-3.611,0-4.031,1.257-4.031,2.2	c0,1.143,0.495,1.477,5.367,2.122c4.825,0.64,7.116,1.544,7.116,4.935c0,3.418-2.853,5.379-7.827,5.379"
              ></path>
            </svg>
          </div>
          <div className="justify-center flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 48 48"
            >
              <linearGradient
                id="iOmQfjoCC4Hw6zVwRjSDha_x7XMNGh2vdqA_gr1"
                x1="21.861"
                x2="25.703"
                y1="8.237"
                y2="36.552"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stop-color="#00c1e0"></stop>
                <stop offset="1" stop-color="#009bb8"></stop>
              </linearGradient>
              <path
                fill="url(#iOmQfjoCC4Hw6zVwRjSDha_x7XMNGh2vdqA_gr1)"
                d="M24,9.604c-5.589,0-9.347,2.439-11.276,7.318c-0.2,0.505,0.417,0.92,0.816,0.551 c2.035-1.882,4.322-2.505,6.86-1.871c1.826,0.456,3.131,1.781,4.576,3.247C27.328,21.236,30.051,24,36,24 c5.589,0,9.348-2.44,11.276-7.319c0.2-0.505-0.417-0.92-0.816-0.551c-2.035,1.882-4.322,2.506-6.86,1.872 c-1.825-0.456-3.13-1.781-4.575-3.247C32.672,12.367,29.948,9.604,24,9.604L24,9.604z M12,24c-5.589,0-9.348,2.44-11.276,7.319 c-0.2,0.505,0.417,0.92,0.816,0.551c2.035-1.882,4.322-2.506,6.86-1.871c1.825,0.457,3.13,1.781,4.575,3.246 c2.353,2.388,5.077,5.152,11.025,5.152c5.589,0,9.348-2.44,11.276-7.319c0.2-0.505-0.417-0.92-0.816-0.551 c-2.035,1.882-4.322,2.506-6.86,1.871c-1.826-0.456-3.131-1.781-4.576-3.246C20.672,26.764,17.949,24,12,24L12,24z"
              ></path>
            </svg>
          </div>
          <div className="justify-center flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 50 50"
            >
              <path d="M7.854,11.705C7.771,9.257,9.628,7,12.139,7h25.726c2.511,0,4.368,2.257,4.285,4.705c-0.08,2.351,0.024,5.397,0.77,7.88	c0.749,2.491,2.012,4.066,4.08,4.268v2.294c-2.068,0.202-3.331,1.777-4.08,4.268c-0.747,2.483-0.85,5.529-0.77,7.88	C42.232,40.743,40.375,43,37.864,43H12.139c-2.511,0-4.368-2.257-4.285-4.705c0.08-2.351-0.024-5.397-0.771-7.88	C6.335,27.924,5.069,26.349,3,26.147v-2.294c2.068-0.202,3.335-1.777,4.083-4.268C7.83,17.102,7.934,14.056,7.854,11.705z"></path>
              <path
                fill="#fff"
                d="M27.79,24.451v-0.029c1.911-0.33,3.402-2.121,3.402-4.094c0-1.395-0.536-2.576-1.551-3.416	c-1.011-0.837-2.431-1.28-4.105-1.28h-7.669v18.734h7.709c2.003,0,3.666-0.489,4.808-1.415c1.144-0.927,1.749-2.276,1.749-3.901	C32.133,26.596,30.389,24.752,27.79,24.451z M24.199,23.568h-3.28v-5.484h3.903c2.141,0,3.32,0.939,3.32,2.645	C28.141,22.56,26.741,23.568,24.199,23.568z M20.919,25.864h3.876c2.8,0,4.22,1.012,4.22,3.007c0,1.993-1.4,3.046-4.048,3.046	h-4.048V25.864z"
              ></path>
            </svg>
          </div>
        </div>
        {/* test 1st  */}
        <div className="grid lg:grid-cols-8 mt-2 mx-[10%]">
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            HTML
          </div>
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            CSS
          </div>
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            Javascript
          </div>
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            React.js
          </div>
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            Vue.js
          </div>
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            Node.js
          </div>
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            Tailwind
          </div>
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            Bootstrap
          </div>
        </div>
        {/* Second layer */}
        <div className="grid lg:grid-cols-7 mx-[14%] mt-10">
          <div className="justify-center flex">
            <img
              src="https://img.icons8.com/?size=100&id=rgPSE6nAB766&format=png&color=000000"
              alt="MySql"
            ></img>
          </div>
          <div className="justify-center flex">
            <img
              src="https://img.icons8.com/?size=100&id=f0R4xVI4Sc8O&format=png&color=000000"
              alt="PHP"
            ></img>
          </div>
          <div className="justify-center flex">
            <img src={laravel} alt="Laravel" className="w-20"></img>
          </div>
          <div className="justify-center flex">
            <img src={livewire} alt="Livewire" className="w-20"></img>
          </div>
          <div className="justify-center flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0,0,256,256"
            >
              <g
                fill="#ffffff"
                fill-rule="nonzero"
                stroke="none"
                stroke-width="1"
                stroke-linecap="butt"
                stroke-linejoin="miter"
                stroke-miterlimit="10"
                stroke-dasharray=""
                stroke-dashoffset="0"
                font-family="none"
                font-weight="none"
                font-size="none"
                text-anchor="none"
              >
                <g transform="scale(10.66667,10.66667)">
                  <path d="M10.9,2.1c-4.6,0.5 -8.3,4.2 -8.8,8.7c-0.5,4.7 2.2,8.9 6.3,10.5c0.3,0.1 0.6,-0.1 0.6,-0.5v-1.6c0,0 -0.4,0.1 -0.9,0.1c-1.4,0 -2,-1.2 -2.1,-1.9c-0.1,-0.4 -0.3,-0.7 -0.6,-1c-0.3,-0.1 -0.4,-0.1 -0.4,-0.2c0,-0.2 0.3,-0.2 0.4,-0.2c0.6,0 1.1,0.7 1.3,1c0.5,0.8 1.1,1 1.4,1c0.4,0 0.7,-0.1 0.9,-0.2c0.1,-0.7 0.4,-1.4 1,-1.8c-2.3,-0.5 -4,-1.8 -4,-4c0,-1.1 0.5,-2.2 1.2,-3c-0.1,-0.2 -0.2,-0.7 -0.2,-1.4c0,-0.4 0,-1 0.3,-1.6c0,0 1.4,0 2.8,1.3c0.5,-0.2 1.2,-0.3 1.9,-0.3c0.7,0 1.4,0.1 2,0.3c1.3,-1.3 2.8,-1.3 2.8,-1.3c0.2,0.6 0.2,1.2 0.2,1.6c0,0.8 -0.1,1.2 -0.2,1.4c0.7,0.8 1.2,1.8 1.2,3c0,2.2 -1.7,3.5 -4,4c0.6,0.5 1,1.4 1,2.3v2.6c0,0.3 0.3,0.6 0.7,0.5c3.7,-1.5 6.3,-5.1 6.3,-9.3c0,-6 -5.1,-10.7 -11.1,-10z"></path>
                </g>
              </g>
            </svg>
          </div>
          <div className="justify-center flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0,0,256,256"
            >
              <g
                fill="#ffffff"
                fill-rule="nonzero"
                stroke="none"
                stroke-width="1"
                stroke-linecap="butt"
                stroke-linejoin="miter"
                stroke-miterlimit="10"
                stroke-dasharray=""
                stroke-dashoffset="0"
                font-family="none"
                font-weight="none"
                font-size="none"
                text-anchor="none"
              >
                <g transform="scale(5.12,5.12)">
                  <path d="M44.08203,25.20703l2.82031,-4.77734c0.11328,-0.23828 0.12891,-0.51172 0.04297,-0.75781l-5.58594,-16c-0.17187,-0.48828 -0.68359,-0.76562 -1.17969,-0.64453l-16.41797,4c-0.3125,0.07813 -0.57031,0.30078 -0.69141,0.60156l-1.74609,4.37109h-5.32422c-0.27734,0 -0.54687,0.11719 -0.73437,0.32031l-12,13c-0.37109,0.40234 -0.35156,1.02734 0.04297,1.40234l12.58594,12c0.18359,0.17969 0.43359,0.27734 0.6875,0.27734h5.73828l1.75,4.37109c0.12891,0.32422 0.41797,0.55469 0.75781,0.61328l17.46094,3c0.05469,0.01172 0.11328,0.01563 0.16797,0.01563c0.46484,0 0.875,-0.32031 0.97656,-0.78516l3.54297,-16.23047c0.05469,-0.25 0.01172,-0.51172 -0.125,-0.73437zM21.32422,12l14.09375,-2.58203l-7.41797,14.58203h-16.83203zM22.32422,39l-11.15625,-11h16.83203l8.75,13.16797zM40.38672,38.89844l-8.38672,-12.89844l7.33203,-14.58203l4.75,13.79297z"></path>
                </g>
              </g>
            </svg>
          </div>
          <div className="justify-center flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 48 48"
            >
              <path
                fill="#00c853"
                d="M22.903,3.286c0.679-0.381,1.515-0.381,2.193,0c3.355,1.883,13.451,7.551,16.807,9.434 C42.582,13.1,43,13.804,43,14.566c0,3.766,0,15.101,0,18.867c0,0.762-0.418,1.466-1.097,1.847 c-3.355,1.883-13.451,7.551-16.807,9.434c-0.679,0.381-1.515,0.381-2.193,0c-3.355-1.883-13.451-7.551-16.807-9.434 C5.418,34.899,5,34.196,5,33.434c0-3.766,0-15.101,0-18.867c0-0.762,0.418-1.466,1.097-1.847 C9.451,10.837,19.549,5.169,22.903,3.286z"
              ></path>
              <path
                fill="#69f0ae"
                d="M5.304,34.404C5.038,34.048,5,33.71,5,33.255c0-3.744,0-15.014,0-18.759 c0-0.758,0.417-1.458,1.094-1.836c3.343-1.872,13.405-7.507,16.748-9.38c0.677-0.379,1.594-0.371,2.271,0.008 c3.343,1.872,13.371,7.459,16.714,9.331c0.27,0.152,0.476,0.335,0.66,0.576L5.304,34.404z"
              ></path>
              <path
                fill="#fff"
                d="M24,10c-7.73,0-14,6.27-14,14s6.27,14,14,14s14-6.27,14-14S31.73,10,24,10z M24,31 c-3.86,0-7-3.14-7-7s3.14-7,7-7s7,3.14,7,7S27.86,31,24,31z"
              ></path>
              <path
                fill="#00e676"
                d="M42.485,13.205c0.516,0.483,0.506,1.211,0.506,1.784c0,3.795-0.032,14.589,0.009,18.384 c0.004,0.396-0.127,0.813-0.323,1.127L23.593,24L42.485,13.205z"
              ></path>
              <path fill="#fff" d="M34 20H35V28H34zM37 20H38V28H37z"></path>
              <path fill="#fff" d="M32 25H40V26H32zM32 22H40V23H32z"></path>
            </svg>
          </div>
        </div>
        {/* text 2nd  */}
        <div className="grid lg:grid-cols-7 mx-[14%] mt-2">
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            MySQL
          </div>
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            PHP
          </div>
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            Laravel
          </div>
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            Livewire
          </div>
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            GitHub
          </div>
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            Unity
          </div>
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            C#
          </div>
        </div>
      </div>
      {/* small device  */}
      <div className="2xl:mx-[10%] mx-[0%] lg:hidden grid">
        {/* first layer */}
        <div className="grid grid-cols-3 mx-[10%] mt-16">
          <div className="justify-center flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 48 48"
              className=""
            >
              <path fill="#E65100" d="M41,5H7l3,34l14,4l14-4L41,5L41,5z"></path>
              <path fill="#FF6D00" d="M24 8L24 39.9 35.2 36.7 37.7 8z"></path>
              <path
                fill="#FFF"
                d="M24,25v-4h8.6l-0.7,11.5L24,35.1v-4.2l4.1-1.4l0.3-4.5H24z M32.9,17l0.3-4H24v4H32.9z"
              ></path>
              <path
                fill="#EEE"
                d="M24,30.9v4.2l-7.9-2.6L15.7,27h4l0.2,2.5L24,30.9z M19.1,17H24v-4h-9.1l0.7,12H24v-4h-4.6L19.1,17z"
              ></path>
            </svg>
          </div>
          <div className="justify-center flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 48 48"
            >
              <path fill="#0277BD" d="M41,5H7l3,34l14,4l14-4L41,5L41,5z"></path>
              <path fill="#039BE5" d="M24 8L24 39.9 35.2 36.7 37.7 8z"></path>
              <path
                fill="#FFF"
                d="M33.1 13L24 13 24 17 28.9 17 28.6 21 24 21 24 25 28.4 25 28.1 29.5 24 30.9 24 35.1 31.9 32.5 32.6 21 32.6 21z"
              ></path>
              <path
                fill="#EEE"
                d="M24,13v4h-8.9l-0.3-4H24z M19.4,21l0.2,4H24v-4H19.4z M19.8,27h-4l0.3,5.5l7.9,2.6v-4.2l-4.1-1.4L19.8,27z"
              ></path>
            </svg>
          </div>
          <div className="justify-center flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 48 48"
            >
              <path fill="#ffd600" d="M6,42V6h36v36H6z"></path>
              <path
                fill="#000001"
                d="M29.538 32.947c.692 1.124 1.444 2.201 3.037 2.201 1.338 0 2.04-.665 2.04-1.585 0-1.101-.726-1.492-2.198-2.133l-.807-.344c-2.329-.988-3.878-2.226-3.878-4.841 0-2.41 1.845-4.244 4.728-4.244 2.053 0 3.528.711 4.592 2.573l-2.514 1.607c-.553-.988-1.151-1.377-2.078-1.377-.946 0-1.545.597-1.545 1.377 0 .964.6 1.354 1.985 1.951l.807.344C36.452 29.645 38 30.839 38 33.523 38 36.415 35.716 38 32.65 38c-2.999 0-4.702-1.505-5.65-3.368L29.538 32.947zM17.952 33.029c.506.906 1.275 1.603 2.381 1.603 1.058 0 1.667-.418 1.667-2.043V22h3.333v11.101c0 3.367-1.953 4.899-4.805 4.899-2.577 0-4.437-1.746-5.195-3.368L17.952 33.029z"
              ></path>
            </svg>
          </div>
        </div>
        {/* text 1st */}
        <div className="grid grid-cols-3 mt-2 mx-[10%]">
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            HTML
          </div>
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            CSS
          </div>
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            Javascript
          </div>
        </div>
        {/* 2nd layer */}
        <div className="grid grid-cols-3 mx-[10%] mt-16">
          <div className="justify-center flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 48 48"
            >
              <path
                fill="#80deea"
                d="M24,34C11.1,34,1,29.6,1,24c0-5.6,10.1-10,23-10c12.9,0,23,4.4,23,10C47,29.6,36.9,34,24,34z M24,16	c-12.6,0-21,4.1-21,8c0,3.9,8.4,8,21,8s21-4.1,21-8C45,20.1,36.6,16,24,16z"
              ></path>
              <path
                fill="#80deea"
                d="M15.1,44.6c-1,0-1.8-0.2-2.6-0.7C7.6,41.1,8.9,30.2,15.3,19l0,0c3-5.2,6.7-9.6,10.3-12.4c3.9-3,7.4-3.9,9.8-2.5	c2.5,1.4,3.4,4.9,2.8,9.8c-0.6,4.6-2.6,10-5.6,15.2c-3,5.2-6.7,9.6-10.3,12.4C19.7,43.5,17.2,44.6,15.1,44.6z M32.9,5.4	c-1.6,0-3.7,0.9-6,2.7c-3.4,2.7-6.9,6.9-9.8,11.9l0,0c-6.3,10.9-6.9,20.3-3.6,22.2c1.7,1,4.5,0.1,7.6-2.3c3.4-2.7,6.9-6.9,9.8-11.9	c2.9-5,4.8-10.1,5.4-14.4c0.5-4-0.1-6.8-1.8-7.8C34,5.6,33.5,5.4,32.9,5.4z"
              ></path>
              <path
                fill="#80deea"
                d="M33,44.6c-5,0-12.2-6.1-17.6-15.6C8.9,17.8,7.6,6.9,12.5,4.1l0,0C17.4,1.3,26.2,7.8,32.7,19	c3,5.2,5,10.6,5.6,15.2c0.7,4.9-0.3,8.3-2.8,9.8C34.7,44.4,33.9,44.6,33,44.6z M13.5,5.8c-3.3,1.9-2.7,11.3,3.6,22.2	c6.3,10.9,14.1,16.1,17.4,14.2c1.7-1,2.3-3.8,1.8-7.8c-0.6-4.3-2.5-9.4-5.4-14.4C24.6,9.1,16.8,3.9,13.5,5.8L13.5,5.8z"
              ></path>
              <circle cx="24" cy="24" r="4" fill="#80deea"></circle>
            </svg>
          </div>

          <div className="justify-center flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 48 48"
            >
              <polygon
                fill="#81c784"
                points="23.987,17 18.734,8 2.974,8 23.987,44 45,8 29.24,8"
              ></polygon>
              <polygon
                fill="#455a64"
                points="29.24,8 23.987,17 18.734,8 11.146,8 23.987,30 36.828,8"
              ></polygon>
            </svg>
          </div>
          <div className="justify-center flex">
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 48 48"
            >
              <path
                fill="#21a366"
                d="M24.007,45.419c-0.574,0-1.143-0.15-1.646-0.44l-5.24-3.103c-0.783-0.438-0.401-0.593-0.143-0.682	c1.044-0.365,1.255-0.448,2.369-1.081c0.117-0.067,0.27-0.043,0.39,0.028l4.026,2.389c0.145,0.079,0.352,0.079,0.486,0l15.697-9.061	c0.145-0.083,0.24-0.251,0.24-0.424V14.932c0-0.181-0.094-0.342-0.243-0.432L24.253,5.446c-0.145-0.086-0.338-0.086-0.483,0	L8.082,14.499c-0.152,0.086-0.249,0.255-0.249,0.428v18.114c0,0.173,0.094,0.338,0.244,0.42l4.299,2.483	c2.334,1.167,3.76-0.208,3.76-1.591V16.476c0-0.255,0.2-0.452,0.456-0.452h1.988c0.248,0,0.452,0.196,0.452,0.452v17.886	c0,3.112-1.697,4.9-4.648,4.9c-0.908,0-1.623,0-3.619-0.982l-4.118-2.373C5.629,35.317,5,34.216,5,33.042V14.928	c0-1.179,0.629-2.279,1.646-2.861L22.36,3.002c0.994-0.562,2.314-0.562,3.301,0l15.694,9.069C42.367,12.656,43,13.753,43,14.932	v18.114c0,1.175-0.633,2.271-1.646,2.861L25.66,44.971c-0.503,0.291-1.073,0.44-1.654,0.44"
              ></path>
              <path
                fill="#21a366"
                d="M28.856,32.937c-6.868,0-8.308-3.153-8.308-5.797c0-0.251,0.203-0.452,0.455-0.452h2.028	c0.224,0,0.413,0.163,0.448,0.384c0.306,2.066,1.218,3.108,5.371,3.108c3.308,0,4.715-0.747,4.715-2.502	c0-1.01-0.401-1.76-5.54-2.263c-4.299-0.424-6.955-1.371-6.955-4.809c0-3.167,2.672-5.053,7.147-5.053	c5.026,0,7.517,1.745,7.831,5.493c0.012,0.13-0.035,0.255-0.122,0.35c-0.086,0.09-0.208,0.145-0.334,0.145h-2.039	c-0.212,0-0.397-0.149-0.44-0.354c-0.491-2.173-1.678-2.868-4.904-2.868c-3.611,0-4.031,1.257-4.031,2.2	c0,1.143,0.495,1.477,5.367,2.122c4.825,0.64,7.116,1.544,7.116,4.935c0,3.418-2.853,5.379-7.827,5.379"
              ></path>
            </svg>
          </div>
        </div>
        {/* Text 2nd */}
        <div className="grid grid-cols-3 mt-2 mx-[10%]">
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            React.js
          </div>
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            Vue.js
          </div>
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            Node.js
          </div>
        </div>
        {/* 3rd layer */}
        <div className="grid grid-cols-3 mx-[10%] mt-16">
          <div className="justify-center flex">
            <img
              src="https://img.icons8.com/?size=100&id=x7XMNGh2vdqA&format=png&color=000000"
              alt="Tailwind Css"
            ></img>
          </div>

          <div className="justify-center flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 50 50"
            >
              <path d="M7.854,11.705C7.771,9.257,9.628,7,12.139,7h25.726c2.511,0,4.368,2.257,4.285,4.705c-0.08,2.351,0.024,5.397,0.77,7.88	c0.749,2.491,2.012,4.066,4.08,4.268v2.294c-2.068,0.202-3.331,1.777-4.08,4.268c-0.747,2.483-0.85,5.529-0.77,7.88	C42.232,40.743,40.375,43,37.864,43H12.139c-2.511,0-4.368-2.257-4.285-4.705c0.08-2.351-0.024-5.397-0.771-7.88	C6.335,27.924,5.069,26.349,3,26.147v-2.294c2.068-0.202,3.335-1.777,4.083-4.268C7.83,17.102,7.934,14.056,7.854,11.705z"></path>
              <path
                fill="#fff"
                d="M27.79,24.451v-0.029c1.911-0.33,3.402-2.121,3.402-4.094c0-1.395-0.536-2.576-1.551-3.416	c-1.011-0.837-2.431-1.28-4.105-1.28h-7.669v18.734h7.709c2.003,0,3.666-0.489,4.808-1.415c1.144-0.927,1.749-2.276,1.749-3.901	C32.133,26.596,30.389,24.752,27.79,24.451z M24.199,23.568h-3.28v-5.484h3.903c2.141,0,3.32,0.939,3.32,2.645	C28.141,22.56,26.741,23.568,24.199,23.568z M20.919,25.864h3.876c2.8,0,4.22,1.012,4.22,3.007c0,1.993-1.4,3.046-4.048,3.046	h-4.048V25.864z"
              ></path>
            </svg>
          </div>
          <div className="justify-center flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 48 48"
            >
              <path
                fill="#00c853"
                d="M22.903,3.286c0.679-0.381,1.515-0.381,2.193,0c3.355,1.883,13.451,7.551,16.807,9.434 C42.582,13.1,43,13.804,43,14.566c0,3.766,0,15.101,0,18.867c0,0.762-0.418,1.466-1.097,1.847 c-3.355,1.883-13.451,7.551-16.807,9.434c-0.679,0.381-1.515,0.381-2.193,0c-3.355-1.883-13.451-7.551-16.807-9.434 C5.418,34.899,5,34.196,5,33.434c0-3.766,0-15.101,0-18.867c0-0.762,0.418-1.466,1.097-1.847 C9.451,10.837,19.549,5.169,22.903,3.286z"
              ></path>
              <path
                fill="#69f0ae"
                d="M5.304,34.404C5.038,34.048,5,33.71,5,33.255c0-3.744,0-15.014,0-18.759 c0-0.758,0.417-1.458,1.094-1.836c3.343-1.872,13.405-7.507,16.748-9.38c0.677-0.379,1.594-0.371,2.271,0.008 c3.343,1.872,13.371,7.459,16.714,9.331c0.27,0.152,0.476,0.335,0.66,0.576L5.304,34.404z"
              ></path>
              <path
                fill="#fff"
                d="M24,10c-7.73,0-14,6.27-14,14s6.27,14,14,14s14-6.27,14-14S31.73,10,24,10z M24,31 c-3.86,0-7-3.14-7-7s3.14-7,7-7s7,3.14,7,7S27.86,31,24,31z"
              ></path>
              <path
                fill="#00e676"
                d="M42.485,13.205c0.516,0.483,0.506,1.211,0.506,1.784c0,3.795-0.032,14.589,0.009,18.384 c0.004,0.396-0.127,0.813-0.323,1.127L23.593,24L42.485,13.205z"
              ></path>
              <path fill="#fff" d="M34 20H35V28H34zM37 20H38V28H37z"></path>
              <path fill="#fff" d="M32 25H40V26H32zM32 22H40V23H32z"></path>
            </svg>
          </div>
        </div>
        {/* Text 3rd */}
        <div className="grid grid-cols-3 mt-2 mx-[10%]">
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            Tailwind
          </div>
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            Bootstrap
          </div>
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            C#
          </div>
        </div>
        {/* 4th layer */}
        <div className="grid grid-cols-3 mx-[10%] mt-16">
          <div className="justify-center flex">
            <img
              src="https://img.icons8.com/?size=100&id=rgPSE6nAB766&format=png&color=000000"
              alt="MySql"
            ></img>
          </div>
          <div className="justify-center flex">
            <img
              src="https://img.icons8.com/?size=100&id=f0R4xVI4Sc8O&format=png&color=000000"
              alt="PHP"
            ></img>
          </div>
          <div className="justify-center flex">
            <img src={laravel} alt="Laravel" className="w-20"></img>
          </div>
        </div>
        {/* Text 4th */}
        <div className="grid grid-cols-3 mt-2 mx-[10%]">
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            MySQL
          </div>
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            PHP
          </div>
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            Laravel
          </div>
        </div>
        {/* 5th layer */}
        <div className="grid grid-cols-3 mx-[10%] mt-16">
          <div className="justify-center flex">
            <img src={livewire} alt="Livewire" className="w-20"></img>
          </div>
          <div className="justify-center flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0,0,256,256"
            >
              <g
                fill="#ffffff"
                fill-rule="nonzero"
                stroke="none"
                stroke-width="1"
                stroke-linecap="butt"
                stroke-linejoin="miter"
                stroke-miterlimit="10"
                stroke-dasharray=""
                stroke-dashoffset="0"
                font-family="none"
                font-weight="none"
                font-size="none"
                text-anchor="none"
              >
                <g transform="scale(10.66667,10.66667)">
                  <path d="M10.9,2.1c-4.6,0.5 -8.3,4.2 -8.8,8.7c-0.5,4.7 2.2,8.9 6.3,10.5c0.3,0.1 0.6,-0.1 0.6,-0.5v-1.6c0,0 -0.4,0.1 -0.9,0.1c-1.4,0 -2,-1.2 -2.1,-1.9c-0.1,-0.4 -0.3,-0.7 -0.6,-1c-0.3,-0.1 -0.4,-0.1 -0.4,-0.2c0,-0.2 0.3,-0.2 0.4,-0.2c0.6,0 1.1,0.7 1.3,1c0.5,0.8 1.1,1 1.4,1c0.4,0 0.7,-0.1 0.9,-0.2c0.1,-0.7 0.4,-1.4 1,-1.8c-2.3,-0.5 -4,-1.8 -4,-4c0,-1.1 0.5,-2.2 1.2,-3c-0.1,-0.2 -0.2,-0.7 -0.2,-1.4c0,-0.4 0,-1 0.3,-1.6c0,0 1.4,0 2.8,1.3c0.5,-0.2 1.2,-0.3 1.9,-0.3c0.7,0 1.4,0.1 2,0.3c1.3,-1.3 2.8,-1.3 2.8,-1.3c0.2,0.6 0.2,1.2 0.2,1.6c0,0.8 -0.1,1.2 -0.2,1.4c0.7,0.8 1.2,1.8 1.2,3c0,2.2 -1.7,3.5 -4,4c0.6,0.5 1,1.4 1,2.3v2.6c0,0.3 0.3,0.6 0.7,0.5c3.7,-1.5 6.3,-5.1 6.3,-9.3c0,-6 -5.1,-10.7 -11.1,-10z"></path>
                </g>
              </g>
            </svg>
          </div>
          <div className="justify-center flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0,0,256,256"
            >
              <g
                fill="#ffffff"
                fill-rule="nonzero"
                stroke="none"
                stroke-width="1"
                stroke-linecap="butt"
                stroke-linejoin="miter"
                stroke-miterlimit="10"
                stroke-dasharray=""
                stroke-dashoffset="0"
                font-family="none"
                font-weight="none"
                font-size="none"
                text-anchor="none"
              >
                <g transform="scale(5.12,5.12)">
                  <path d="M44.08203,25.20703l2.82031,-4.77734c0.11328,-0.23828 0.12891,-0.51172 0.04297,-0.75781l-5.58594,-16c-0.17187,-0.48828 -0.68359,-0.76562 -1.17969,-0.64453l-16.41797,4c-0.3125,0.07813 -0.57031,0.30078 -0.69141,0.60156l-1.74609,4.37109h-5.32422c-0.27734,0 -0.54687,0.11719 -0.73437,0.32031l-12,13c-0.37109,0.40234 -0.35156,1.02734 0.04297,1.40234l12.58594,12c0.18359,0.17969 0.43359,0.27734 0.6875,0.27734h5.73828l1.75,4.37109c0.12891,0.32422 0.41797,0.55469 0.75781,0.61328l17.46094,3c0.05469,0.01172 0.11328,0.01563 0.16797,0.01563c0.46484,0 0.875,-0.32031 0.97656,-0.78516l3.54297,-16.23047c0.05469,-0.25 0.01172,-0.51172 -0.125,-0.73437zM21.32422,12l14.09375,-2.58203l-7.41797,14.58203h-16.83203zM22.32422,39l-11.15625,-11h16.83203l8.75,13.16797zM40.38672,38.89844l-8.38672,-12.89844l7.33203,-14.58203l4.75,13.79297z"></path>
                </g>
              </g>
            </svg>
          </div>
        </div>
        {/* Text 5th */}
        <div className="grid grid-cols-3 mt-2 mx-[10%]">
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            Livewire
          </div>
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            GitHub
          </div>
          <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
            Unity
          </div>
        </div>
      </div>
    </div>
  );
}
export default Skills;
