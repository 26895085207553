import React from "react";
import "./Homepage.css";
import Waves from "../components/Waves";
import About from "../components/About";
import FlippedWaves from "../components/FlippedWaves";
import Education from "../components/Eduction";
import Certifications from "../components/Certifications";
import Skills from "../components/Skills";
import Contact from "../components/Contact";
import Work from "../components/Work";
import Footer from "../components/Footer";

function Homepage() {
  return (
    <div>
      <Waves></Waves>
      <About></About>
      <FlippedWaves></FlippedWaves>
      <Education></Education>
      <Work></Work>
      <Certifications></Certifications>
      <Skills></Skills>
      <Contact></Contact>
      <Footer></Footer>
    </div>
  );
}

export default Homepage;
