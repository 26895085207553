import glances from "../Glances.png";

function Work() {
  return (
    <div className="text-center mb-32">
      <div className="text-[65px] text-zinc-300 font-black ysabeau-sc">
        Experience
      </div>
      <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
        My current work experience
      </div>

      <div className="grid lg:grid-cols-10 mx-[10%] mt-20 text-zinc-200 gap-6 text-left">
        {/* First Box */}
        <div className="col-span-4 lg:inline hidden">
          <div className="py-4 font-black ysabeau-sc rounded-xl bg-gray-800 border-r-2 border-zinc-900 flex items-start justify-start p-2 space-x-4 w-full">
            <img
              src={glances}
              alt="Glances"
              className="rounded-full w-20 flex-shrink-0 ml-4"
            />
            <div className="flex flex-col justify-start items-start w-full text-left">
              <div className="text-[28px]">Glances</div>
              <div className="text-[20px]">Fullstack Developer Intern</div>
            </div>
          </div>
        </div>

        {/* Second Box */}
        <div className="col-span-6 font-black ysabeau-sc rounded-xl bg-gray-800 border-r-2 border-zinc-900">
          <div className="p-4">
            <div className="text-[28px]">Fullstack Developer Intern</div>
            <div className="text-[18px] my-2 text-zinc-400">Glances</div>
            <div className="pb-3 border-b">2023-Present</div>
            <div className="text-lg">
              <div className="mt-3">
                Worked with dynamic frontend and backend codebases to create
                software.
              </div>
              <div className="my-3">
                Created and managed MySQL database tables
              </div>
              <div>Worked with Vue, laravel, Php, and Livewire</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Work;
