import React from "react";
import { motion } from "framer-motion";
import "./Components.css";

const educationExperienceData = [
  {
    date: "2023 - Present",
    title: "Fullstack Developer Intern",
    description: "Working at Glances, building fullstack applications",
  },
  {
    date: "2024",
    title: "High School Diploma",
    description:
      "Completed high school at Whitehouse High School in Whitehouse TX.",
  },
  {
    date: "2024 - Present",
    title: "University of Texas at Tyler Honors Student",
    description:
      "Working on my Bachelor of Science, with focus on Computer Information Systems",
  },
];

function Education() {
  return (
    <div className="text-center mt-16">
      <div className="text-[65px] text-zinc-300 font-black ysabeau-sc">
        Timeline
      </div>
      <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
        What I have done so far
      </div>

      <div className="flex">
        <div className="justify-center ">
          <div className="timeline-container mt-8 ">
            {educationExperienceData.map((item, index) => (
              <motion.div
                className="timeline-item"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, ease: "easeOut" }}
              >
                <div className="timeline-date">{item.date}</div>
                <div className="timeline-content">
                  <h3 className="timeline-title">{item.title}</h3>
                  <p className="timeline-description text-zinc-400">
                    {item.description}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Education;
