import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import {
  Navbar,
  Collapse,
  Typography,
  Button,
  IconButton,
} from "@material-tailwind/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

var name = "<Owen Maddox>";

// Reusable NavItem Component
const NavItem = ({ children }) => (
  <Typography
    as="li"
    variant="small"
    color="blue-gray"
    className="p-1 font-normal"
  >
    <div href="#" className="flex items-right">
      {children}
    </div>
  </Typography>
);

function NavbarSimple() {
  const [openNav, setOpenNav] = useState(false);

  // Close nav when window resizes
  useEffect(() => {
    const handleResize = () => window.innerWidth >= 960 && setOpenNav(false);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Navigation list
  const navList = (
    <ul className="my-2 flex flex-col gap-2 lg:flex-row items-end lg:gap-6  font-mono font-semibold">
      {["About", "Education", "Work", "Certifications"].map((item, index) => (
        <NavItem key={index}>{item}</NavItem>
      ))}
    </ul>
  );

  return (
    <div className="w-full overflow-scroll">
      <Navbar className="absolute top-0 z-10 h-max max-w-full bg-gray-950 rounded-none px-8 py-2 border-0 shadow-none">
        <div className="py-1.5 grid grid-cols-12">
          <Link
            to="/"
            className="mr-4 cursor-pointer my-2.5 lg:col-span-4 col-span-11 text-xl underline-none atma-medium name-gradient"
          >
            {name}
          </Link>

          <div className="mr-4 hidden lg:block col-span-6 text-right place-items-end my-2.5 font-medium">
            <span className="mr-4 cursor-pointer">About</span>
            <span className="mr-4 cursor-pointer">Education</span>
            <span className="mr-4 cursor-pointer">Work</span>
            <span className="mr-4 cursor-pointer">Certifications</span>
          </div>

          <Button
            variant="outlined"
            size="sm"
            className="hidden lg:inline-block rounded-full text-white col-span-2"
          >
            Contact
          </Button>

          <IconButton
            variant="text"
            className="lg:hidden bg-zinc-50 hover:bg-zinc-200"
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <XMarkIcon className="h-6 w-6" strokeWidth={2} />
            ) : (
              <Bars3Icon className="h-6 w-6" strokeWidth={2} />
            )}
          </IconButton>
        </div>

        <Collapse open={openNav} className="">
          {navList}
          <Button
            fullWidth
            variant="outlined"
            size="sm"
            className="block lg:hidden rounded-full text-white py-2.5 mb-4"
          >
            Contact
          </Button>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default NavbarSimple;
