function Footer() {
  return (
    <div>
      <div className="header lg:h-[60vh] md:h-[55vh] h-[40vh] -z-10">
        <svg
          className="waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="parallax">
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="0"
              fill="rgba(255,255,255,0.7)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="3"
              fill="rgba(255,255,255,0.5)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="5"
              fill="rgba(255,255,255,0.3)"
            />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
          </g>
        </svg>
      </div>
      <div className=" text-[14px] text-left bg-cyan-500 text-zinc-800 pr-2 pt-5 ysabeau-sc font-thin pb-10 ">
        <div className="mx-[7%] grid md:grid-cols-12 grid-cols-1">
          <div className="md:col-span-12 border-b-2 border-zinc-800  mb-10"></div>
          <div className="md:col-span-3 text-center">owen@maddoxbox.com</div>
          <div className="md:col-span-6"></div>
          <div className="md:col-span-3 text-center">Icons by icons8.com</div>
        </div>
      </div>
    </div>
  );
}
export default Footer;
