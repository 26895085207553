import "./Components.css";
import headshot from "../Headshot.png";

function About() {
  return (
    <div className="py-32 bg">
      <div className="bg-gray-900 mx-[8%] lg:rounded-2xl rounded-xl  px-10 pt-8">
        <div className="text-zinc-400 ysabeau-sc font-medium">Introduction</div>
        <div className="grid md:grid-cols-10 grid-cols-1 font-sans font-semibold text-md">
          <div className="md:col-span-4">
            <div className="grid grid-cols-1 w-full mt-10">
              <div className="justify-center flex">
                <div className="bg-zinc-500/80 rounded-[100%] w-[150px] h-[150px] overflow-hidden">
                  <img
                    src={headshot}
                    alt="Headshot"
                    className=" w-[150px] mt-2"
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 mt-10">
              <div className="justify-center flex">
                <button className="bg-zinc-300 hover:bg-zinc-400 hover:text-zinc-900 p-1.5 px-3 rounded-full">
                  Resume
                </button>
              </div>
              <div className="justify-center flex">
                <a
                  href="https://www.linkedin.com/in/owen-maddox-624587260/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="bg-zinc-300 hover:bg-zinc-400 p-1.5 px-3 rounded-full hover:text-zinc-900">
                    LinkedIn
                  </button>
                </a>
              </div>
            </div>
            <div className="grid grid-cols-1">
              <div className="justify-center flex">
                <a
                  href="https://github.com/OwenMaddox"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="bg-zinc-300 hover:bg-zinc-400 hover:text-zinc-900 p-1.5 px-3 rounded-full mt-6">
                    GitHub
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="md:col-span-6 text-zinc-400">
            <div className="space-y-6 my-[12%]">
              <div className="">
                I am a Fullstack Developer with 2 years of professional
                experience in software development.
              </div>
              <div className="">
                Currently working towards my Honors Bachelor of Science in
                Computer Information Systems at the University Of Texas At
                Tyler.
              </div>
              <div className="">
                I enjoy developing complex software and solving difficult
                problems.
              </div>
              <div className="">
                I am always curious and constantly learning.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default About;
