function Contact() {
  return (
    <div className="text-center mt-48">
      <div className="md:text-[65px] text-[55px] text-zinc-300 font-black ysabeau-sc ">
        Contact
      </div>
      <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
        Get in touch
      </div>
      <div className="mt-4 grid grid-cols-2 gap-[5%] lg:mx-[25%] mx-[10%] text-zinc-300">
        <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin text-left ml-2">
          Name
        </div>
        <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin text-left ml-2">
          Email
        </div>
        <input
          className="mt-1 bg-gray-800 p-3 rounded-lg ysabeau-sc text-xl"
          placeholder="Your Name"
        ></input>

        <input
          className="mt-1 bg-gray-800 p-3 rounded-lg ysabeau-sc text-xl"
          placeholder="Your Email"
        ></input>
      </div>
      <div className="grid grid-cols-1 mt-10 lg:mx-[25%] mx-[10%] text-zinc-300">
        <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin text-left ml-3">
          Message
        </div>
        <textarea
          className="mt-2 bg-gray-800 p-3 rounded-lg ysabeau-sc text-xl"
          rows={5}
          placeholder="Hey Owen, I love the website! I'd like to talk about an opportunity you might like!"
        ></textarea>
      </div>
    </div>
  );
}
export default Contact;
