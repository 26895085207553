import UnityCert from "../UnityCert.png";
import CompTIA from "../CompTIACert.png";

function Certifications() {
  return (
    <div className="text-center mt-16">
      <div className="md:text-[65px] text-[55px] text-zinc-300 font-black ysabeau-sc ">
        Certifications
      </div>
      <div className="text-[20px] text-zinc-400 ysabeau-sc font-thin">
        My current certifications
      </div>
      <div className="grid lg:grid-cols-2 grid-cols-1 xl:mx-[25%] xl:gap[10%] lg:mx-[10%] lg:gap-[5%] md:mx-[25%] sm:mx-[20%] mx-[10%] text-[30px] text-zinc-700 font-black ysabeau-sc mt-20 ">
        <div className="bg-zinc-50 lg:rounded-2xl rounded-lg pb-4 mt-4">
          <div className="mt-2 mx-[4%]">CompTIA IT Fundamentals+</div>
          <div className="border-b border-zinc-800 mt-2 mb-6 mx-[10%]"></div>
          <img
            src={CompTIA}
            alt="CompTIA Cert Logo"
            className="w-[70%] mx-[15%]"
          ></img>
          <a
            href="https://www.credly.com/badges/5821ab77-42fe-43e9-9c32-c6ad54579bb4/linked_in?t=sd9w4x"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="mt-6 text-2xl">View Certification</div>
          </a>
        </div>
        <div className="bg-zinc-50 lg:rounded-2xl rounded-lg pb-4 mt-4">
          <div className="mt-2 mx-[2%]">Unity Certified Programmer</div>
          <div className="border-b border-zinc-800 mt-2 mb-6 mx-[10%]"></div>
          <img
            src={UnityCert}
            alt="Unity Cert Logo"
            className="w-[70%] mx-[15%]"
          ></img>
          <a
            href="https://www.credly.com/badges/f5e6b91f-cc91-46f8-bbe6-6d2d21ce918e/linked_in?t=rurtrn"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="mt-6 text-2xl">View Certification</div>
          </a>
        </div>
      </div>
    </div>
  );
}
export default Certifications;
